<template>
  <div class="relative">
    <div class="flex-mid body-max-width">
      <form method="GET" action="/payment-waiting-credit">
        <component
          :is="'script'"
          type="text/javascript"
          src="https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js"
          data-apikey="pkey_test_21202R91a3hMaT2l7YMTxNaNAsEAy8aTDWNEu"
          :data-amount="totalPrice"
          data-currency="THB"
          data-payment-methods="card"
          data-name="Anythings would works!"
          data-mid="401991211230001"
        >
        </component>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
export default defineComponent({
  name: "CreditCardPayment",
  // components: {
  //     Header: require("@/components/HomeNav.vue").default,
  // },
  data() {
    return {
      accessToken: "",
      isLoading: true,
      lang: "th",
      totalPrice: 0,
      checkReload: false,
      orderId: "",
    };
  },
  mounted() {
    this.access_token = window.localStorage.getItem("access_token");
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
    this.totalPrice = window.localStorage.getItem("totalPrice");
    this.checkReload = window.localStorage.getItem("checkReload");
    this.orderId = window.localStorage.getItem("orderId");
    window.localStorage.setItem("orderId", this.orderId);
    window.localStorage.setItem("totalPrice", this.totalPrice);
    console.log(typeof this.checkReload);
    console.log(this.checkReload);
    if (this.checkReload == "false") {
      this.reloadPage();
      console.log("checkReload", this.checkReload);
    }
    window.onload = function () {
      document.getElementsByClassName("pay-button")[0].click();
      console.log(this.$refs);
    };
  },
  methods: {
    redirect(redirect) {
      window.location.href = redirect;
    },
    reloadPage() {
      this.checkReload = true;
      window.localStorage.setItem("checkReload", this.checkReload);
      window.location.reload();
    },
  },
});
</script>

<style lang="scss" scoped>
$color-on-hover: #fbfff5;

html,
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

.hospital-name {
  font-size: 1.4rem;
}

.fast-pay-button {
  width: 30%;
  height: 4rem;
  background-color: #429928;
  color: #ffffff;
  font-size: 1.8rem;
  text-align: center;
  border-radius: 2rem;
}
</style>
